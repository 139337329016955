<template>
  <div class='loginBox'>
    <div class="mainBox">
      <div class="logoBox"></div>
      <div class="centerBox">
        <div class="title">登录</div>

        <div class="loginList">
          <div class='transformBtn' :class='[toggleLoginBy?"":"active"]' style='margin-right:30px'>
            <div @click='toggleLogin("byAccount")'>账号登录</div>
            <span></span>
          </div>
          <div class='transformBtn' :class='[toggleLoginBy?"active":""]'>
            <div @click='toggleLogin("byCode")'>扫码登录</div>
            <span></span>
          </div>
        </div>
        <!-- 账号登录 -->
        <div v-if='!toggleLoginBy' class="loginItem">
          <el-form :model="loginRuleForm" :rules="rules" ref="ruleForm">
            <el-form-item prop="tel">
              <el-input clearable v-model.trim="loginRuleForm.tel" placeholder='请输入手机号'></el-input>
            </el-form-item>
            <el-form-item class='margin0 mrt30' prop="pwd">
              <el-input @keyup.enter="toLogin" clearable v-model.trim="loginRuleForm.pwd" show-password placeholder='请输入密码'></el-input>
            </el-form-item>
            <el-form-item class='margin0'>
              <div class='textLine'>
                <div class='register'>您还没有账号？<router-link to='/register'>立即注册</router-link>
                </div>
                <div style='text-align:right;padding-right:10px'>
                  <router-link to='/ResetPwd'>忘记密码</router-link>
                </div>
              </div>
            </el-form-item>
            <el-form-item>
              <el-button :loading="loading" class='mainBtn' round @click="toLogin" >登 录</el-button>
            </el-form-item>
          </el-form>
        </div>
        <!-- 扫码登录 -->
        <div v-else class="loginItem">
          <div class="codeImg" v-loading='codeLoading'>
            <img :src="codeImgSrc" v-show='codeImgSrc'>
          </div>
          <div class='codeLoginText'>请用手机打开微信，扫描二维码登录</div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import {
    ref
  } from 'vue';
  import {
    useRouter
  } from 'vue-router'
  import {
    useStore
  } from 'vuex'
  import {
    isTel
  } from '@/utils/index.js';
  import {
    login,
    getTmpSubscribeQrCode,
    getTokenByTicket
  } from '@/api/index.js';
  import {
    ElMessage
  } from 'element-plus';
  import {
    getUserInfo
  } from '@/api/account.js'
  // 登录方式切换
  const useLoginType = () => {
    const toggleLoginBy = ref(0);
    let codeLoading = ref(true);
    const router = useRouter()
    const store = useStore()
    let timer = ref(null)
    let codeImgSrc = ref('');
    const toggleLogin = async (type) => {
      if (type === 'byCode') {
        toggleLoginBy.value = 1;
        let ticket = await getTmpSubscribeQrCode({
          sceneStr: 'qrScanLogin'
        });
        codeLoading.value = false;
        console.log('ticket:', ticket)
        if (ticket && ticket.data && ticket.code == 200) {
          let ticketData = ticket.data.ticket;
          codeImgSrc.value = `https://mp.weixin.qq.com/cgi-bin/showqrcode?ticket=${ticketData}`;
          let scanInfo = null
          const scanInfoFn = () => {
            if (scanInfo) {
              return
            }
            timer.value = setTimeout(async () => {
              let res = await getTokenByTicket({
                ticket: ticketData
              })
              if (res.code == 200) {
                if (res.data.success) {
                  clearTimeout(timer.value)
                  if (res.data.scanInfo) {
                    scanInfo = res.data.scanInfo
                    if (scanInfo.hasBind) {
                      ElMessage.success("登录成功")
                      sessionStorage.setItem('token', res.data.scanInfo.data.value);
                      
                      await store.dispatch('getRoutes', res.data.scanInfo.data.value);
                      router.replace('/home/homeMain');
                    }else{
                      sessionStorage.setItem("openId",res.data.scanInfo.data)
                      router.replace('/bindWx')
                    }
                  }
                } else {
                  scanInfoFn()
                }
              }
            }, 3000);
          }
          scanInfoFn()
          // timer.value = setInterval(async () => {
          //   let res = await getTokenByTicket({ticket: ticketData})
          //   if(res.code == 200){
          //     if(res.data.success){
          //       clearInterval(timer.value)
          //       if(res.data.scanInfo.success){
          //         ElMessage.success("登录成功")
          //         sessionStorage.setItem('token',res.data.scanInfo.data.value);
          //         await store.dispatch('getRoutes',res.data.scanInfo.data.value);
          //         router.replace('/home/homeMain');
          //       }else{
          //         ElMessage.error(res.data.scanInfo.msg)
          //       }
          //     }
          //   }
          // }, 3000);
        }
      };
      if (type === 'byAccount') {
        toggleLoginBy.value = 0
        clearTimeout(timer.value)
      };
    };
    return {
      toggleLoginBy,
      toggleLogin,
      codeLoading,
      codeImgSrc
    }
  };
  export default {
    name: "Login",
    data() {
      return {
        rules: {
          tel: [{
              required: true,
              message: '请输入手机号',
              trigger: 'blur'
            },
            {
              pattern: isTel,
              message: '手机号格式错误',
              trigger: 'blur'
            }
          ],
          pwd: [{
            required: true,
            message: '请输入密码',
            trigger: 'blur'
          }]
        },
        loginRuleForm: {
          tel: '',
          pwd: ''
        },
        loading: false
      }
    },
    methods: {
      toLogin() {
        this.$refs['ruleForm'].validate(async (valid) => {
          if (valid) {
            this.loading = true;
            let formData = new FormData();
            formData.append('grant_type', 'password')
            formData.append('username', this.loginRuleForm.tel)
            formData.append('password', this.loginRuleForm.pwd)
            let res = await login(formData);
            this.loading = false;
            if (res && res.code == 200) {
              sessionStorage.setItem('token', res.data.value);
              await this.$store.dispatch('getRoutes', res.data.value);
              // 获取用户信息
              let userInfoRes = await getUserInfo();
              if(userInfoRes&&userInfoRes.code==200){
                this.$store.commit('getUserInfo',{
                  userName:userInfoRes.data.userName,
                  userTel:userInfoRes.data.tel
                });
                this.$router.replace('/home/homeMain');
              }
            }
          } else {
            return false;
          }
        });
      },
    },
    setup() {
      const {
        toggleLoginBy,
        toggleLogin,
        codeLoading,
        codeImgSrc,
        timer
      } = useLoginType();
      return {
        toggleLoginBy,
        toggleLogin,
        codeLoading,
        codeImgSrc,
        timer
      }
    }
  }
</script>

<style scoped lang='scss'>
  @import '@/assets/style/comman.scss';

  .loginBox {
    width: 100%;
    height: 100vh;
    background: #FFC696;
    position: relative;

    .mainBox {
      @include container;
      height: 100%;
      background: rgba(0, 0, 0, 0);
      display: flex;
      align-items: center;
      justify-content: center;

      .centerBox {
        width: 500px;
        height: 530px;
        box-sizing: border-box;
        margin: auto;
        padding: 30px;
        display: flex;
        flex-direction: column;
        align-items: center;
        background: #fff;
        border-radius: 10px;
        box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.2);

        .title {
          font-size: 32px;
          font-weight: 700;
          letter-spacing: 5px;
          color: $main-color
        }

        .loginList {
          display: flex;
          width: 356px;
          align-items: center;
          margin: 80px 0 10px 0;

          .transformBtn {
            font-size: 13px;
            font-weight: 400;
            color: #666;
            position: relative;

            div {
              position: relative;
              z-index: 10;

              &:hover {
                cursor: pointer;
              }
            }

            span {
              display: none;
            }
          }

          .active {
            font-size: 16px;
            font-weight: 700;
            color: #555;

            span {
              display: block;
              position: absolute;
              z-index: 5;
              left: 50%;
              margin-left: -35px;
              bottom: -2px;
              width: 70px;
              height: 6px;
              background: linear-gradient(263deg, rgba(255, 255, 255, 1) -78%, rgba(255, 134, 0, 1) 162%);
              border-radius: 2px;
              box-shadow: 2px 2px 5px rgba(236, 128, 0, 0.4)
            }
          }
        }

        .loginItem {
          width: 360px;

          ::v-deep {
            .el-input__inner {
              border-radius: 20px
            }
          }

          .el-form-item {
            margin-top: 40px
          }

          .margin0 {
            margin: 0 !important;
          }

          .mrt30 {
            margin-top: 30px !important;
            margin-bottom: 20px !important;
          }

          .mainBtn {
            @include mainBtn
          }

          .textLine {
            display: flex;
            align-items: center;
            justify-content: space-around;
            width: 360px;

            >div {
              width: 50%;
              box-sizing: border-box;
              font-size: 12px;
              color: #666;

              a {
                color: #666;
                text-decoration: none;

                &:hover {
                  text-decoration: underline;
                }
              }
            }

            >.register {
              a {
                color: $main-color;
                margin-left: 5px
              }
            }
          }

          .codeImg {
            width: 230px;
            height: 230px;
            margin: 30px auto 20px auto;

            img {
              width: 100%;
              display: block;
            }
          }

          .codeLoginText {
            font-size: 12px;
            color: #555;
            text-align: center;
            letter-spacing: 1px;
          }
        }
      }
    }
  }
</style>